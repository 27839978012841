<template>
  <div class="user-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
  let validUsername = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("账号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "用户列表",
          search: [{
              key: "username",
              label: "账号",
               maxlength: 16,
              rules: [{
                validator: validUsername,
                trigger: ['blur']
              }]
            },
            {
              key: "realname",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "roleId",
              label: "角色",
              type: "select",
              opt: {
                list: []
              }
            },
            // {
            //   key: "idCardNo",
            //   label: "身份证"
            // },
            {
              key: "userstate",
              label: "账号状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "禁用",
                    value: "1"
                  },
                  {
                    label: "启用",
                    value: "2"
                  }
                ]
              }
            }
          ],
          columns: [{
              label: "账号",
              key: "username",
              on: row => {
                _this.$router.push("/main/user/engineering/show/" + row.userId);
              }
            },
            // { label: "身份证号码", key: "idCardNo" },
            // { label: "电话", key: "phoneNum" },
            {
              label: "姓名",
              key: "realname"
            },
            {
              label: "角色",
              key: "roleNames"
            },
            {
              label: "手机号码",
              key: "workNo"
            },
            // { label: "添加时间", key: "createdDate" },
            {
              label: "账号状态",
              key: "userStateStr",
              type: "switch",
              opt: {
                on(form) {
                  return _this
                    .post(
                      "user-service/user/modifyUserAccountState", {
                        userId: form.userId,
                        userState: !form.userStateStr ? "2" : "1"
                      }, {
                        isUseResponse: true,
                        isNotShowError: true,
                        operatorFlage: true,
                        operateName: !form.userStateStr ? "启用了账号" : "禁用了账号",
                      }
                    )
                    .then(res => {
                      if (res.data.code == 0) {
                        return {
                          state: true
                        };
                      }
                    }).catch((res) => {
                      return {
                        state: false,
                        msg: res.data.msg
                      };
                    });
                }
              }
            }
          ],
          buttons: [{
              type: 0,
              on() {
                _this.$router.push("/main/user/engineering/add/0");
              }
            },
            {
              type: 1,
              on(data) {
                _this.$router.push(
                  "/main/user/engineering/edit/" + data.data.userId
                );
              }
            }
            // {
            //   type: 2,
            //   on(data) {
            //     var dto = {
            //       ids: data.ids
            //     };
            //     _this.post("/user/del", dto).then(function() {
            //       _this.$message({
            //         showClose: true,
            //         message: "删除成功!",
            //         type: "success"
            //       });
            //       data.refresh();
            //     });
            //   }
            // }
          ]
        }
      };
    },
    created() {
      console.log("user-list created!!");
    },
    activated() {
      this.getMenuName(this.$route.path)
      this.getRole()
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          ...opt.searchForm
        };
        // 工程授权角色账号登录 需新增的入参 roleCode
        let roleCodeVal = null;
        if(this.cache.getLS("userInfo")) {
          if(this.cache.getLS("userInfo").roleCodes) {
            roleCodeVal = this.cache.getLS("userInfo").roleCodes[0]
          }
        }
        if(roleCodeVal == 'project_authorization') {
          dto.roleCode = 'engineer';
        }

        this.post("user-service/user/listSystemUser", dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data && res.data.code == 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              let item = res.data.data[i];
              item.userStateStr = item.userstate == 2 ? true : false;
              item.roleNames = item.roleNames.length ?
                item.roleNames.join(",") :
                "";
              // if (item.userstate == 1) {
              //   item.bk = 2;
              // } else {
              //   item.bk = 0;
              // }
            }
          }
          opt.cb(res.data);
        });
      },
      getRole() {
        this.post("user-service/apartmentuser/selectRoleList",
          {
            roleType: "1"
          },{
            isUseResponse: true
          })
        .then(res => {
          let roleList = res.data.data
          roleList = JSON.parse(JSON.stringify(roleList).replace(/roleName/g, "label"));
          roleList = JSON.parse(JSON.stringify(roleList).replace(/roleId/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == 'roleId') {
              item.opt.list = roleList
            }
          })
        });
      }
    }
  };
</script>